
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import store from "@/store";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const userData = computed(() => {
      return store.getters.currentUser;
    });

    const permissionData = computed(() => {
      return store.getters.currentPermission;
    });
    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      return text;
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const isAllowed = (item) => {
      console.log(item.moduleName);
      if (userData.value.role == "ADMIN") {
        return true;
      } else {
        if (["DASHBOARD"].indexOf(item.moduleName) > -1) {
          return true;
        }
        if (permissionData.value[item.moduleName]) {
          return permissionData.value[item.moduleName].read;
        }
      }
    };
    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      userData,
      isAllowed,
    };
  },
});
