
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";
import store from "@/store";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const route = useRoute();
    const userData = computed(() => {
      return store.getters.currentUser;
    });
    const permissionData = computed(() => {
      return store.getters.currentPermission;
    });
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const translate = (text) => {
      return text;
    };

    const isAllowed = (item) => {
      console.log(item)
      if (userData.value.role == "ADMIN") {
        return true;
      } else {
        if (item.moduleName == "DASHBOARD") {
          return true;
        }
        if (permissionData.value[item.moduleName]) {
          return permissionData.value[item.moduleName].read;
        }
      }
    };
    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      userData,
      permissionData,
      version,
      isAllowed,
    };
  },
});
