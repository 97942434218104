
import TGNotification, {
  getNotification,
  TGNotifications,
  viewNotification,
} from "@/core/data/notifications";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "notifications-menu",
  components: {},
  setup() {
    const isLoading = ref<boolean>(true);
    const currentPage = ref<any>(1);
    const search = ref<string>("");
    const tableData = ref<Array<TGNotification>>(TGNotifications);
    const data1 = [
      {
        title: "Project Alice",
        description: "Phase 1 development",
        time: "1 hr",
        icon: "media/icons/duotune/technology/teh008.svg",
        state: "primary",
      },
      {
        title: "HR Confidential",
        description: "Confidential staff documents",
        time: "2 hrs",
        icon: "media/icons/duotune/general/gen044.svg",
        state: "danger",
      },
      {
        title: "Company HR",
        description: "Corporeate staff profiles",
        time: "5 hrs",
        icon: "media/icons/duotune/finance/fin006.svg",
        state: "warning",
      },
      {
        title: "Project Redux",
        description: "New frontend admin theme",
        time: "2 days",
        icon: "media/icons/duotune/files/fil023.svg",
        state: "success",
      },
      {
        title: "Project Breafing",
        description: "Product launch status update",
        time: "21 Jan",
        icon: "media/icons/duotune/maps/map001.svg",
        state: "primary",
      },
      {
        title: "Banner Assets",
        description: "Collection of banner images",
        time: "21 Jan",
        icon: "media/icons/duotune/general/gen006.svg",
        state: "info",
      },
      {
        title: "Icon Assets",
        description: "Collection of SVG icons",
        time: "20 March",
        icon: "media/icons/duotune/art/art002.svg",
        state: "warning",
      },
    ];
    const getListItems = function (pageNumber) {
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }
      return new Promise((res, rej) => {
        getNotification(currentPage.value, search.value).then((resObj: any) => {
          isLoading.value = false;
          tableData.value.splice(0, tableData.value.length, ...resObj.data);
          res(resObj);
        });
      });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Items Listing", ["Apps", "Items"]);
      getListItems(1);
    });
    return {
      tableData,
      viewNotification,
    };
  },
});
