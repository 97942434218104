const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        role: ["ADMIN"],
        moduleName: "DASHBOARD",
      },
      {
        sectionTitle: "Users",
        route: "/users",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        role: ["ADMIN"],
        sub: [
          {
            heading: "Users Listing",
            route: "/users/users-listing",
          },
        ],
        moduleName: "USER",
      },
      {
        sectionTitle: "Role",
        route: "/role",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        role: ["ADMIN"],
        sub: [
          {
            heading: "Role Listing",
            route: "/role/role-listing",
          },
        ],
        moduleName: "ROLE",
      },
      {
        sectionTitle: "Material",
        route: "/material",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        role: ["ADMIN"],
        sub: [
          {
            heading: "Material Listing",
            route: "/material/material-listing",
          },
        ],
        moduleName: "MATERIAL",
      },
      {
        sectionTitle: "Party",
        route: "/party",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        role: ["ADMIN"],
        sub: [
          {
            heading: "Party Listing",
            route: "/party/party-listing",
          },
        ],
        moduleName: "PARTY",
      },
      {
        sectionTitle: "Season",
        route: "/season",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        role: ["ADMIN"],
        sub: [
          {
            heading: "Season Listing",
            route: "/season/season-listing",
          },
        ],
        moduleName: "PARTY",
      },
      {
        sectionTitle: "Design",
        role: ["ADMIN"],
        route: "/design",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Design Listing",
            route: "/design/design-listing",
          },
        ],
        moduleName: "DESIGN",
      },
      {
        sectionTitle: "Production",
        role: ["ADMIN"],
        route: "/production",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Production Listing",
            route: "/production/production-listing",
          },
        ],
        moduleName: "PRODUCTION",
      },
      {
        sectionTitle: "Unused Stock",
        role: ["ADMIN", "STAFF", "MANAGER"],
        route: "/unused-stock",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Unused Stock Listing",
            route: "/unused-stock/unused-stock-listing",
          },
        ],
        moduleName: "UNUSED_STOCK",
      },
      {
        sectionTitle: "Delivery Challan",
        role: ["ADMIN", "STAFF", "MANAGER"],
        route: "/delivery-challan",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Delivery Challan Listing",
            route: "/delivery-challan/delivery-challan-listing",
          },
        ],
        moduleName: "DELIVERY_CHALLAN",
      },
    ],
  },
];

export default DocMenuConfig;
